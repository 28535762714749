// extracted by mini-css-extract-plugin
export var articleWrapper = "j_n3";
export var articleText = "j_n4 g_fv g_ds";
export var header = "j_n5 g_b2 g_bz";
export var headerImageWrapper = "j_n6 g_ch g_b3 g_cc g_cb g_b7 g_cd g_ck g_cR";
export var headerGradient = "j_n7 g_ch g_b3 g_cc g_cb g_b7 g_cd";
export var headerGradientOverlay = "j_n8 g_ch g_b3 g_cc g_cb g_b7 g_cd";
export var headerContentWrapper = "j_n9 g_cz g_cP g_cD g_cJ g_cl g_b2";
export var contentWrapper = "j_q g_bz";
export var dateTag = "j_pb g_df g_dr g_fq g_bz";
export var icon1 = "j_pc g_dP";
export var icon2 = "j_pd g_dP g_dJ";
export var tagBtn = "j_pf g_c1 g_dD g_fp g_fB";
export var headerText = "j_pg g_bz g_df g_ds g_cW";
export var center = "j_ph g_fw";
export var videoIframeStyle = "j_pj g_f0 g_bz g_bL g_cy g_c1 g_bV";
export var articleImageWrapper = "j_pk g_ds g_b2";
export var articleImageWrapperIcon = "j_pl g_ds g_b2";
export var articleRow = "j_pm g_cD g_z";
export var quoteWrapper = "j_hG g_cC g_cP g_ds";
export var quoteBar = "j_pn g_bL";
export var quoteText = "j_pp";
export var authorBox = "j_pq g_bz";
export var authorRow = "j_pr g_cD g_cW g_dy";
export var separator = "j_ps g_bz";
export var line = "j_gd g_bz g_gd g_dY g_dy";
export var authorImage = "j_l7 g_c7 g_b2 g_bz g_dt";
export var authorText = "j_pt g_dh g_dt";
export var masonryImageWrapper = "j_pv";
export var bottomSeparator = "j_pw g_bz g_dy";
export var linksWrapper = "j_px g_dh g_fv";
export var comments = "j_py g_cD g_dy";
export var sharing = "j_pz g_cz g_cD g_cJ";
export var shareText = "j_pB g_bz g_fw";
export var icon = "j_pC";
export var text = "j_pD";
export var SubtitleSmall = "j_pF";
export var SubtitleNormal = "j_pG";
export var SubtitleLarge = "j_pH";