// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "D_gK g_gK g_cz g_cH";
export var navbarDividedRight = "D_gL g_gL g_cz";
export var menuLeft = "D_t5 g_gH g_cz g_fw g_cP";
export var menuRight = "D_t6 g_gH g_cz g_fw g_cP";
export var menuCenter = "D_t7 g_gJ g_gH g_cz g_fw g_cP g_bz g_cD";
export var menuDivided = "D_p4 g_gJ g_gH g_cz g_fw g_cP g_bz g_cD";
export var navbarItem = "D_p5 g_cx";
export var navbarLogoItemWrapper = "D_gQ g_gQ g_cC g_cP";
export var burgerToggle = "D_t8 g_g4 g_cy g_cs g_b2";
export var burgerToggleOpen = "D_t9 g_g4 g_cy g_cs g_b6";
export var burgerInput = "D_vb g_gV g_bB g_cy g_b3 g_dc g_fl g_ct";
export var burgerLine = "D_vc g_gT g_cy g_b2 g_bB g_cm g_dp";
export var burgerMenuLeft = "D_vd g_gZ g_gY g_gW g_gX g_b3 g_dc g_fm g_cs g_fw";
export var burgerMenuRight = "D_vf g_g0 g_gY g_gW g_gX g_b3 g_dc g_fm g_cs g_fw";
export var burgerMenuCenter = "D_vg g_g1 g_gY g_gW g_gX g_b3 g_dc g_fm g_cs g_fw";
export var burgerMenuDivided = "D_vh g_gZ g_gY g_gW g_gX g_b3 g_dc g_fm g_cs g_fw";
export var btnWrapper = "D_m g_dX g_dQ g_cz g_cH g_bz";
export var cancelBtn = "D_vj g_c1 g_dD g_fB";
export var icon = "D_pC";
export var secondary = "D_vk g_cC g_cP";