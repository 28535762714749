// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "r_kx g_kx g_dv";
export var galleryMasonryImage = "r_bc g_bc g_bz g_cR g_fB";
export var alignLeft = "r_pJ g_gj g_cG g_fv";
export var alignCenter = "r_cP g_gk g_cD g_fw";
export var alignRight = "r_pK g_gl g_cH g_fx";
export var galleryContainer = "r_q9 g_fS";
export var galleryContainerFull = "r_rb g_fQ";
export var galleryRowWrapper = "r_rc g_dc";
export var galleryGuttersImage = "r_kz g_kz g_bN g_dv";
export var galleryNoGuttersImage = "r_ky g_ky g_bN g_dD";
export var galleryTextGutters = "r_kv g_kv g_dw";
export var galleryTextNoGutters = "r_kw g_kw g_dw";
export var galleryTextMasonry = "r_rd g_kv g_dw";
export var galleryImageWrapper = "r_rf g_f9 g_b2";
export var descText = "r_rg g_kB g_b3 g_cd g_cb g_cc g_br";
export var guttersDesc = "r_rh";