// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rj g_hn g_ds";
export var heroHeaderCenter = "s_hp g_hp g_ds g_fw";
export var heroHeaderRight = "s_hq g_hq g_ds g_fx";
export var heroParagraphLeft = "s_rk g_hk g_dw";
export var heroParagraphCenter = "s_hl g_hl g_dw g_fw";
export var heroParagraphRight = "s_hm g_hm g_dw g_fx";
export var heroBtnWrapperLeft = "s_rl g_m g_fX g_bz g_cz g_cG";
export var heroBtnWrapperCenter = "s_rm g_fY g_fX g_bz g_cz g_cD";
export var heroBtnWrapperRight = "s_rn g_fZ g_fX g_bz g_cz g_cH";
export var overlayBtnWrapper = "s_rp g_hj g_b3 g_cb g_cc g_cd g_cn g_dc";
export var design4 = "s_rq g_hh g_b3 g_cb g_cc g_cn";
export var heroExceptionSmall = "s_rr C_rr";
export var heroExceptionNormal = "s_rs C_rs";
export var heroExceptionLarge = "s_rt C_rt";
export var Title1Small = "s_rv C_rv C_s4 C_s5";
export var Title1Normal = "s_rw C_rw C_s4 C_s6";
export var Title1Large = "s_rx C_rx C_s4 C_s7";
export var BodySmall = "s_ry C_ry C_s4 C_tn";
export var BodyNormal = "s_rz C_rz C_s4 C_tp";
export var BodyLarge = "s_rB C_rB C_s4 C_tq";