// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "y_sy g_hJ g_dw g_fv";
export var quoteParagraphCenter = "y_hK g_hK g_dw g_fw";
export var quoteParagraphRight = "y_hL g_hL g_dw g_fx";
export var quoteRowLeft = "y_sz g_cG";
export var quoteRowCenter = "y_sB g_cD";
export var quoteRowRight = "y_sC g_cH";
export var quoteWrapper = "y_hG g_hG g_bz g_dD g_cz g_cP g_cD";
export var quoteContentWrapper = "y_hH g_hH";
export var quoteExceptionSmall = "y_sD C_sD";
export var quoteExceptionNormal = "y_sF C_sF";
export var quoteExceptionLarge = "y_sG C_sG";
export var quoteAuthorExceptionSmall = "y_sH C_sH";
export var quoteAuthorExceptionNormal = "y_sJ C_sJ";
export var quoteAuthorExceptionLarge = "y_sK C_sK";