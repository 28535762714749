// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "B_sW g_cD";
export var storyRowWrapper = "B_jm g_jm g_cK";
export var storyLeftWrapper = "B_sX g_cz g_cP";
export var storyWrapperFull = "B_sY g_dD";
export var storyWrapperFullLeft = "B_nP g_dD g_cz g_cP g_cD";
export var contentWrapper = "B_q g_jn";
export var storyLeftWrapperCenter = "B_sZ g_jp g_cz g_cD g_cP g_cJ";
export var storyRightWrapperCenter = "B_s0 g_jt";
export var storyHeader = "B_s1 g_js g_bz g_ds";
export var storyHeaderCenter = "B_jr g_jr g_bz g_ds g_fw g_cy";
export var storyParagraphCenter = "B_jq g_jq g_cy g_fw";
export var storyBtnWrapper = "B_s2 g_m g_fX g_bz g_cz g_cG";
export var storyBtnWrapperCenter = "B_jw g_jw g_fX g_bz g_cz g_cD";
export var imageWrapper = "B_sR g_f9 g_b2";
export var imageWrapperFull = "B_s3 g_bz g_bL g_z g_b2";