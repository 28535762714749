// extracted by mini-css-extract-plugin
export var alignLeft = "w_pJ g_gj g_cG g_fv";
export var alignCenter = "w_cP g_gk g_cD g_fw";
export var alignRight = "w_pK g_gl g_cH g_fx";
export var menuContainer = "w_st g_fS";
export var menuContainerFull = "w_sv g_fQ";
export var menuMainHeader = "w_kl g_kl g_bz g_d3";
export var menuComponentWrapper = "w_kp g_kp g_dv";
export var menuComponentWrapperDesign2 = "w_kq g_kq g_dy";
export var menuComponentText = "w_km g_km g_dn";
export var menuComponentTextDesign2 = "w_kn g_kn g_ds";
export var menuImageWrapperDesign2 = "w_kr g_kr g_bz g_b2";