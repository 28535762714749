// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qG g_gj g_cG g_fv";
export var alignLeft = "q_pJ g_gj g_cG g_fv";
export var alignDiscCenter = "q_qH g_gk g_cD g_fw";
export var alignCenter = "q_cP g_gk g_cD g_fw";
export var alignDiscRight = "q_qJ g_gl g_cH g_fx";
export var alignRight = "q_pK g_gl g_cH g_fx";
export var footerContainer = "q_qK g_fS g_cW";
export var footerContainerFull = "q_qL g_fQ g_cW";
export var footerHeader = "q_kY g_kY";
export var footerTextWrapper = "q_qM g_bz";
export var footerDisclaimerWrapper = "q_k3 g_k3 g_dk";
export var badgeWrapper = "q_qN g_bz g_cz g_cP g_cF g_cJ";
export var footerDisclaimerRight = "q_k4 g_k4 g_cz";
export var footerDisclaimerLeft = "q_k5 g_k5 g_cz";
export var verticalTop = "q_qP g_cz g_cJ g_cN g_cL";
export var firstWide = "q_qQ";
export var disclaimer = "q_qR";
export var socialDisclaimer = "q_qS";
export var left = "q_qT";
export var wide = "q_qV g_dy";
export var right = "q_qW g_cK";
export var line = "q_gd g_gf g_dv";
export var badgeDisclaimer = "q_qX g_cC g_cP g_cJ";
export var badgeContainer = "q_qY g_cz g_cH g_cP";
export var badge = "q_qZ";
export var padding = "q_q0 g_d7";
export var end = "q_q1 g_cH";
export var linkWrapper = "q_q2 g_fB";
export var link = "q_s g_fB";
export var colWrapper = "q_q3 g_dx";
export var media = "q_q4 g_cx g_fy";
export var itemRight = "q_q5";
export var itemLeft = "q_q6";
export var itemCenter = "q_q7";
export var exceptionWeight = "q_q8 C_tw";